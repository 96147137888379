import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetch_elements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements_xlsx(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_element_nit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/companynit`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContactTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/contact_type', { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSupplierTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/supplier_type', { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/cases/company/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/cases/company`, elementData.data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/company/${elementData.data.id}`, elementData.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepartments(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 2 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, {parent}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 3, parent: parent } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchImage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/image/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
